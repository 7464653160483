<template src="./ViewAll.html"></template>

<script>
import OfferThumbnail from "@/components/OfferThumbnail/OfferThumbnail";
import EmployerOfferThumbnail from "@/components/EmployerOfferThumbnail/EmployerOfferThumbnail";
import ArrobaMedellinHorizontalCard from "@/components/ArrobaMedellinHorizontalCard/ArrobaMedellinHorizontalCard";
import ArrobaMedellinHorizontalCardAcademic from "@/components/ArrobaMedellinHorizontalCardAcademic/ArrobaMedellinHorizontalCardAcademic";
import ArrobaMedellinVerticalCardEvents from "@/components/ArrobaMedellinVerticalCardEvents/ArrobaMedellinVerticalCardEvents";
import ArrobaMedellinHorizontalCardIdi from "@/components/ArrobaMedellinHorizontalCardIdi/ArrobaMedellinHorizontalCardIdi";
import createEditEvent from "@/components/Events/createEditEvent";
import showEventDetails from "@/components/Events/showEventDetails";
import { mapGetters } from "vuex";
import eventsService from "@/services/Events";
import ArrobaMedellinArrobitaNoData from "@/components/ArrobaMedellinArrobitaNoData/ArrobaMedellinArrobitaNoData";

export default {
  name: "ViewAll",
  components: {
    OfferThumbnail,
    EmployerOfferThumbnail,
    ArrobaMedellinHorizontalCard,
    ArrobaMedellinHorizontalCardAcademic,
    ArrobaMedellinHorizontalCardIdi,
    ArrobaMedellinVerticalCardEvents,
    createEditEvent,
    ArrobaMedellinArrobitaNoData,
    showEventDetails,
  },
  props: {
    title: {
      type: String,
    },
    component: {
      type: String,
    },
    data: {
      type: Array,
    },
    classCard: {
      type: String,
      default: "blue",
    },
    background: {
      type: String,
    },
    TitleNotFound: {
      type: String,
      default: "Mis cursos",
    },
    notFound: {
      type: String,
      default: "No tienes cursos activos en éste momento",
    },
    program: {
      type: String,
      default: "Sin programa",
    },
  },
  data() {
    return {
      typeCard: { button: `button--${this.classCard}`, progress: "is-info" },
      columnWidth: 6,
      isComponentModalActive: false,
      dataModalComponent: {},
      modalComponent: "",
    };
  },
  mounted() {
    if (!this.data) this.$router.go(-1);
    this.$refs.background.style.background =
      "url(" + this.background + ") center no-repeat cover";
    if (this.component === "ArrobaMedellinVerticalCardEvents")
      this.columnWidth = 4;
  },
  methods: {
    showEventDetails(data) {
      if (this.user.role.name === "Administrator") {
        this.isComponentModalActive = true;
        this.dataModalComponent = { ...data };
        this.modalComponent = "showEventDetails";
      }
    },
    editEvent(eventData) {
      if (this.user.role.name === "Administrator") {
        this.isComponentModalActive = true;
        this.dataModalComponent = { ...eventData };
        this.modalComponent = "createEditEvent";
      }
    },
    async deleteEvent(eventData) {
      this.$buefy.dialog.confirm({
        title: "Eliminar evento",
        type: "is-green",
        message: "¿Estás seguro que quieres eliminar esta evento?",
        confirmText: "Eliminar evento",
        cancelText: "Cancelar",
        onConfirm: async () => {
          try {
            await eventsService.deleteEvent(eventData.id);
            if (this.$route.name !== "Events")
              this.$router.push({ name: "Events" });
            this.$buefy.toast.open({
              duration: 5000,
              message: "Evento eliminado exitosamente",
              position: "is-bottom",
              type: "is-success",
            });
          } catch (e) {
            this.$buefy.toast.open({
              duration: 5000,
              message: e.response.data.message,
              position: "is-bottom",
              type: "is-danger",
            });
          }
        },
      });
    },
  },
  computed: {
    ...mapGetters({
      user: "getUser",
    }),
  },
};
</script>

<style scoped lang="scss" src="./ViewAll.scss">
</style>
